.number-card-base {
  width: 100%;
  max-width: 28rem;
  border-radius: 4px;
}

.number-card-dark {
  color: #adde34;
  background-color: #022213;
}

.number-card {
  border: 1px solid #d2d6dc;
  background-color: white;
}
