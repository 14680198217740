.base-provider-menu {
  padding: 10px 1rem;
  border-bottom: 1px solid #d2d6dc;
  width: 100%;
}

.base-provider-selector {
  grid-column: span 5 / span 5;
}

@media (max-width: 767px) {
  .base-provider-menu {
    padding: 16px;
  }
}

@media (min-width: 768px) {
  .base-provider-menu {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    column-gap: 0.5rem /* 8px */;
  }
}
