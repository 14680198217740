.navbar_nav-item {
  padding: 0px 10px;
  color: #909f99;
}

.navbar_list {
  height: 100%;
  background-color: #022213;
}

.navbar_nav-button {
  transition: padding 0.2s ease-in-out;
}

.navbar_org-title {
  color: #adde34 !important;
}

.navbar_divider {
  border-top: 1px solid #adde34;
  margin: 20px;
}

.navbar_nav-button-selected {
  border-left: 5px solid #adde34 !important;
  color: white !important;
}

.navbar_nav-icon-selected {
  color: #adde34;
}
